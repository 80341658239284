.item-sm-title {
    color: #E58924;
    font-family: var(--Family, "Montserrat");
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 37.6px; 
    letter-spacing: -0.205px;
}

.item-sm-value {
    color: #060606;
    font-family: var(--Family, "Montserrat");
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 37.6px; 
    letter-spacing: -0.205px;
}

.send-message-card {
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.08);
    padding: 1rem;
}

.send-message-card .card-title {
    color: #383838;
    text-align: center;
    font-family: var(--Family, "Montserrat");
    font-size: 21px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.send-message-card .card-label {
    color: rgba(0, 0, 0, 0.80);
    font-family: var(--Family, "Montserrat");
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.send-message-card .card-input, .send-message-card .card-text {
    border-radius: 10px;
    background: #F4F4F4;
    padding: 8px 20px 8px 10px;
    outline: none;
    border: none;
}

.send-message-card .submit-btn {
    display: flex;
    width: 100%;
    padding: 16px 50px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
    background: var(--couleur-1, linear-gradient(308deg, #DB242A 4.2%, #E58924 64.82%));
    color: #FFF;
    font-family: var(--Family, "Montserrat");
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.72px;
    text-transform: uppercase;
    border: 1.3px solid transparent;
    transition: .4s ease;
}

.send-message-card .submit-btn:hover {
    border-color: #DB242A;
    background: transparent;
    color: #DB242A;
}

.custom-input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.maps-container {
    padding-top: 5rem;
    margin-bottom: -3.4rem;
}

.maps-container .maps-frame {
    width: 100%;
}