.cart-qty-text {
    color: #e44c4c !important;
    font-size: 0, 75rem !important;
}

.cart-price-text {
    color: #e44c4c !important;
}

.cartItem-img {
    width: 90px;
    height: 100px;
    object-fit: cover;
}