.footer-component {
  background: linear-gradient(45deg, #E58924, #DB242A);
  padding-top: 30px;
  padding-bottom: 10px;
}

.link-footer {
  color: var(--White, #FFF);
  font-family: var(--Family, "Montserrat");
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px;
  padding-bottom: 15px;
  text-decoration: none;
}
.link-footer:hover {
  font-weight: 700;
  color: #ffffff;
}
footer .copyright {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
}


.link-footer-title {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: #ffffff;
}

.footer-contact-us-item {
  display: flex;
  gap: 5px;
  align-items: flex-start;
}

.title-footer-deco {
  position: relative;
}
.title-footer-deco::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 10px;
  width: 40px;
  height: 3px;
  background: #fff;
}
.link-footer-deco {
  padding-left: 30px !important;
  position: relative;
}
.link-footer-deco::before {
  background: #fff;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  content: "";
  position: absolute;
  left: 0;
  top: 30%;
}

.newsletter-content {
  padding: 2rem 0;
  background: #FEFAF6;
  color: #333;
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  line-height: 20px; /* 111.111% */
}

.newsletter-content .sm-title {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.newsletter-content .sm-value {
  font-size: 14px;
  font-weight: 600;
}

.newsletter-content .email-content {
  position: relative;
}

.newsletter-content .email-content input {
  height: 50px;
  border-radius: 26px;
  border: 1px solid #E8EAEC;
  background: #FFF;
  width: 100%;
  color: #9A9A9A;
  font-family: var(--Family, "Montserrat");
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  outline: none;
  padding-left: 16px; 
}

.newsletter-content .email-content button {
  border: 2px solid transparent;
  border-radius: 50%;
  width: 52px;
  height: 52px;
  background: var(--couleur-2, #E58924);
  font-size: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all .3s ease;
}

.newsletter-content .email-content button:hover {
  background: transparent;
  border-color: var(--couleur-2, #E58924);
  color: var(--couleur-2, #E58924);
}