.empty-header {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  padding: 50px;
}

.auth-right-side-container {
  padding: 100px 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-form-container {
  padding: 25px 30px;
  background: #fff;
  box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.04),
    0px 4px 56px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
}

.title-auth-form-text {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  line-height: 39px;
  text-align: center;
  color: #000000;
}



.auth-form-group {
  padding: 15px 40px;
  color: rgba(0, 0, 0, 0.6);
  position: relative;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.22);
  background: #fff;
}

.auth-form-group-rounded {
  background: #F7F7F7;
  padding: 15px 40px;
  color: rgba(0, 0, 0, 0.6);
  position: relative;
  border-radius: 10px;
}

.auth-row .icon-input {
  position: absolute;
  left: 20px;
  top: 17px;
  color: #C4C4C4;
  font-size: 20px;
}

.auth-row .auth-form-control, 
.auth-row .forget-password-link, 
.auth-row .custom_check__content {
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 3px;
}

.auth-row .auth-form-control {
  color: #CACACA;
  font-size: 14px;
}

.auth-row .custom_check__content {
  color: var(--noir, #000);
  font-size: 12px;
}

.auth-row .forget-password-link {
  color: #E58924;
  font-size: 12px;
}

.auth-row .custom_check__content label {
  padding-left: 5px;
}

.auth-row .custom_check__content input[type="checkbox"]:checked {
  background: #E58924;
  color: #fff;
  border-color: #E58924;
}

.auth-row .custom_check__content input[type="checkbox"]:focus {
  box-shadow: none;
  border-color: #E58924;
}

.auth-row .custom_check__content a {
  color: #E58924;
  font-family: Montserrat;
  font-size: 12px;
  text-decoration: none;
}

.auth-row .auth-form-control {
  background: transparent;
  padding: 10px;
  border: none;
  margin-left: 15px;
}

.auth-row .forget-password-link {
  text-decoration: none;
}


.auth-form-control:focus {
  border: 0;
  box-shadow: none;
}

.form-group.auth-form-group {
  margin-bottom: 40px;
}

.content-forget-password-link {
  display: flex;
  justify-content: flex-end;
}




.auth-form-text {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: rgba(15, 32, 52, 0.4);
}

.auth-row .auth-submit-btn {
  display: flex;
  width: 295px;
  padding: 16px 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: var(--couleur-1, linear-gradient(308deg, #DB242A 4.2%, #E58924 64.82%));
  color: #FFF;
  font-family: var(--Family, "Montserrat");
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.72px;
  /* text-transform: uppercase; */
}

.auth-row .auth-submit-btn:hover {
  background: transparent;
  color: #DB242A;
  border: 1px solid #DB242A;
}

.auth-row.row .auth-col {
  padding: 0 !important;
}

.form-control:focus {
  box-shadow: none !important;
}

.auth-form-label {
  color: #1A1A1A;
  font-family:  var(--Family, "Montserrat");
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: start;
}

.auth-form-message {
  font-family:  var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #0f2034;
}

.show-hide-password {
  position: absolute;
  right: 2%;
  color: #E58924;
  top: 17px;
  font-size: 20px;
}

.text-message-forgepassword {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #333333;
}

.row-footer-register-tabs {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #e5e7f3;
  border-radius: 20px;
  padding: 15px;
}

.content-footer-register-tabs-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.text-footer-register-tabs-item {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 600;
  font-size: 15.681px;
  line-height: 187.7%;
  letter-spacing: 0.02em;
  /* text-transform: uppercase; */
  color: #28348a;
  margin-bottom: 0;
}

.line-border-left {
  width: 1px;
  height: 30px;
  background: #28348a;
}

.title-register-page {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 39px;
  color: #28348a;
}

.sidebar-tabs-container .tabs-container {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-left: 0;
  margin-bottom: 30px;
}

.sidebar-tabs-container .tab-item {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #28348a !important;
  padding: 15px;
}

.sidebar-tabs-container .tab-active-item {
  background: rgba(40, 52, 138, 0.1);
  border-radius: 10px;
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #28348a !important;
}

.content-col-right-register-page {
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
}

.title-form-register {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  color: var(--themeColor,  #E58924);
}

.sub-title-form-register {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(217, 217, 217, 0.6);
}


.form-control-register-tabs {
  background: #F8F8F8;
  border: 1px solid #DCDCDE;
  border-radius: 5px;
  padding: 15px !important;
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.06em;
  color: #000000;
}

.form-control-register-tabs-tel {
  background: #F8F8F8 !important;
  border: 1px solid #DCDCDE !important;
  border-radius: 5px !important;
  /* padding: 15px !important; */
  font-family: var(--Family, "Montserrat");
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  letter-spacing: 0.06em !important;
  color: #000000 !important;
  min-height: 48px !important;
  width: 100% !important;
}

.password-rule-item {
  color: #e20613;
}

.container-default-adresse {
  background: #f5f6fd;
  /* border: 0.5px solid #e20613; */
  border-radius: 3px;
  padding: 15px;
}

.container-default-adresse.active {
  border: 0.5px solid #e20613;
}

.content-info-default-adresse {
  display: flex;
  justify-content: space-between;
}

.text-info-adresse {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 8px;
}

.text-info-adresse-right {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #e20613;
  text-align: right;
}

.icon-edit-default-adresse {
  color: rgba(102, 102, 102, 0.4);
  font-size: 20px;
}

.content-col-form-livraison {
  background: #f5f6fd;
  border: 1px solid #dcdcde;
  border-radius: 5px;
  padding: 15px;
}

.input-checkbox-register-tabs[type="checkbox"] {
  background: #28348a !important;
}

.title-col-left-mode-livraison {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 700;
  font-size: 11.2112px;
  line-height: 13px;
  color: #2a71d5;
}

.content-logo-mode-livraison {
  background: #ffffff;
  box-shadow: 0px 2.80279px 2.80279px rgba(0, 0, 0, 0.04);
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-logo-easy-market-item {
  display: flex;
  gap: 10px;
}

.title-info-livraison {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.text-info-livraison {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #bdbdbd;
}

.icon-mode-paiement-position {
  position: absolute;
  right: 15px;
  top: 13px;
}

.titre-panier-vide {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.07em;
  color: #000000;
}

.text-panier-vide {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.07em;
  color: #000000;
}

.content-panier-vide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-rate-product-item {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: #65635f;
}

.note-product-item-rate {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 500;
  font-size: 62px;
  line-height: 73px;
  color: #000;
}

.text--rate-product-item {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: #65635f;
}

.col-avis-rate-produit-item {
  display: flex;
  gap: 5px;
  align-items: center;
}

.note-avis-libelle {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}

.content-note-rate-avis {
  background: #f5f5f5;
  height: 17px;
  width: 60%;
}

/* .react-tel-input .form-control {
  outline: none;
  background: #f5f6fd !important;
  border: 1px solid #dcdcde !important;
  border-radius: 5px !important;
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 16px;
  letter-spacing: 0.06em;
  color: #000000 !important;
  width: 100% !important;
  height: inherit !important;
  padding-top: 11px !important;
  padding-bottom: 11px !important;
} */
.show-hide-password-modal {
  position: absolute;
  right: 3%;
  top: 53%;
  color: #28348a;
}

.react-select-zone__control {
  background: #f5f6fd !important;
  border: 1px solid #dcdcde !important;
  border-radius: 5px !important;
  padding: 5px !important;
  font-family: var(--Family, "Montserrat");
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  letter-spacing: 0.06em !important;
  color: #000000 !important;
}




.auth-row .tabs-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  border-bottom: 1.276px solid #D9D9D9;
}

.auth-row .tabs-container .tab-item {
  color: #000;
  text-align: center;
  font-family:  var(--Family, "Montserrat");
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
  padding: 18px 6px;
  position: relative;
}

.auth-row .tabs-container .tab-item-active {
  font-weight: 600;
}

.auth-row .tabs-container .tab-item-active:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 90%;
  margin: 0 auto;
  height: 3.28px;
  background: #E58924;
}

.auth-row .text-description-forget-password {
  color: #292929;
  text-align: center;
  font-family:  var(--Family, "Montserrat");
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px; 
}




@media (max-width: 767px) {
  .auth-right-side-container {
    padding: 100px 10%;
  }
}