.filter-select-product {
  background: #ffffff;
  border: 0.5px solid #e20613;
  border-radius: 5px;
  padding: 15px;
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.419636px;
  color: #252733;
  background: url(../../../assets//icons/chevron_left.png) 96% 50% / 13px no-repeat #fff;
  width: 200px;
}

.content-col-left-achat-page {
  background: #ffffff;
  border: 0.5px solid #dddbde;
  border-radius: 10px;
  padding: 15px;
}

.content-title-section-left {
  color: rgba(0, 0, 0, 0.80);
  font-family: var(--Family, "Montserrat");
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.btn-see-more {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-transform: capitalize;
  color: #28348a;
  padding-left: 0;
}

.btn-see-more:hover {
  color: #28348a;
  font-weight: 700;
}

.content-back-to {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.875px;
  text-transform: uppercase;
  color: var(--themeColor,  #E58924);
  cursor: pointer;
}

.container-product-item-page {
  background: #ffffff;
  border: 0.5px solid #cacaca57;
  padding: 30px;
  width: 100%;
}



.category-product-name {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: 4px;
  color: #999999;
}

.text-describe-rate-product {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 24px;
  text-decoration-line: underline;
  color: rgba(12, 12, 12, 0.6);
}

.product-image-carousels {
  display: flex;
  justify-content: center;
}

.text-describe-rate-product-item {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #65635f;
}

.container-rate-product {
  display: flex;
  gap: 10px;
  align-items: center;
}

.btn-theme-outline {
  border: 1px solid #999999;
  border-radius: 10px;
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #000;
  padding: 9px;
  transition: all 0.2s;
  background: #fff;
}

.btn-theme-outline.added_to_card {
  border: 1px solid #206D23;
  color: #206D23;
}

.btn-theme-outline:hover {
  color: #E58924;
  border: 1px solid #E58924;
  background: #fff;
  font-weight: 700;
}

.title-section-product-item {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 500;
  font-size: 27px;
  line-height: 48px;
  color: #252525;
}


.text-describe-product-item,
.text-describe-product-item p,
.text-describe-product-item ol,
.text-describe-product-item ul,
.text-describe-product-item pre,
.text-describe-product-item blockquote,
.text-describe-product-item h1,
.text-describe-product-item h2,
.text-describe-product-item h3,
.text-describe-product-item h4,
.text-describe-product-item h5,
.text-describe-product-item h6 {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: #65635f;
}

._1Lo2h {
  justify-content: space-between !important;
  background: #ddd9d94f;
  border-radius: 10px;
  padding: 15px;
}

._2Jtxm {
  text-align: left !important;
}

._2Jtxm ._1hzhf {
  top: 10% !important;
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  color: #E58924 !important;
  white-space: nowrap;
  padding-left: 15px;
  position: relative !important;
  transform: none;
  left: 0 !important;
}

._2Jtxm ._2kL0S,
._2Jtxm ._2JvrO {
  height: 24px;
  width: 24px;
  font-size: 12px;
  font-size: 10px;
  color: #fff;
  background: rgba(226, 6, 19, 0.1);
}

._2Jtxm._35Ago ._2kL0S {
  background-color: #E58924;
}

._3uApM {
  justify-content: inherit;
  gap: 20px;
}

._2Jtxm._2ZUAI ._2JvrO {
  background-color: #E58924;
}

._2Jtxm::before {
  display: none;
}

._2pGos {
  padding: 10px 15px;
  text-decoration: none;
}

._3CDiP {
  border: none;
}

._hsN1w,
._hsN1w:hover {
  background-color: #E58924;
  border: 1px solid #E58924;
  color: white;
}

.content-col-right-finaliser-commande {
  background: #ffffff;
  border: 1px solid #d2d2d9;
  border-radius: 5px;
  padding: 15px;
}

.title-table-rate {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #E58924;
}

.text-value-table-rate {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.text-libelle-table-rate {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #65635f;
}

.b-1-color {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.btn-add-product-to-panier {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 600;
  font-size: 13.3019px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  background: #f4f9fe;
  border-radius: 2.66038px;
  padding: 12px;
  transition: all 0.2s;
}

.btn-add-product-to-panier:hover {
  background: #e44c4c;
  color: #fff;
}

.btn-add-product-to-panier:hover svg {
  color: #fff !important;
}

.nom-produit-sim {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #000000;
}

.info-produit-sim-value {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 700;
  font-size: 18.6226px;
  line-height: 28px;
  color: #e44c4c;
}

.icon-fav-product-sim {
  position: absolute;
  right: 2px;
  color: rgba(32, 14, 50, 0.2);
}

.img-produit-sim {
  width: 167.6px;
  height: 196.87px;
}


.image-gallery-slide .image-gallery-image {
  width: 300px !important;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 400px !important;
}


.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 30px !important;
  color: #B76102 !important;
}

.image-gallery-play-button {
  display: none;
}

.image-gallery {
  width: 100%;
}

.container-galery-detail-product {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.image-gallery-fullscreen-button {
  color: #E58924 !important;
}

.easy-market-slider {
  width: 100%;
  max-width: 200px;
}

.easy-market-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #dcdcdc;
  border: 5px solid #e20613;
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
  top: 12px;
}

.easy-market-thumb.active {
  background-color: grey;
}

.easy-market-track {
  position: relative;
  background: #dcdcdc;
}

.easy-market-track.easy-market-track-1 {
  background: #e20613;
}

.easy-market-slider .easy-market-track {
  top: 15px;
  height: 4px;
}

.easy-market-slider .mungano-thumb {
  top: 12px;
  width: 10px;
  outline: none;
  height: 10px;
  line-height: 38px;
}

.price-container {
  border-radius: 55px;
  border: 0.5px solid #D9D9D9;
  color: rgba(0, 0, 0, 0.70);
  font-family: var(--Family, "Montserrat");
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: .3rem .8rem;
}

.btn-price-selected {
  background: #E58924;
  border: 0.5px solid #E58924;
  border-radius: 10px;
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
}

.btn-price-selected:hover {
  border: 0.5px solid #E58924;
  color: #E58924;
  background: #fff;
}

.content-logo-easy-market-item.active {
  border: 1.4px solid #e20613;
  padding: 0.4rem;
  border-radius: 10px;
}

.category-filter-acordion {
  --bs-accordion-body-padding-x: 0.25rem !important;
  --bs-accordion-body-padding-y: 1rem !important;
}

.category-filter-acordion-btn {
  width: 100% !important;
  color: #252733 !important;

  text-align: start;
  font-size: 15px;
  font-weight: 700;
}

.category-filter-acordion-item {
  border: none !important;
}

.category-filter-acordion-header {
  box-shadow: 0 0px 1px rgba(0, 0, 0, .11);
  border-radius: 5px;
}

.input-range__track--active,
.input-range__slider {
  background: #E58924 !important;
}

.input-range__slider {
  border: 1px solid #E58924 !important;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 12px !important;
  margin-left: -0.5rem;
  margin-top: -0.48rem !important;
  outline: none;
  position: absolute;
  top: 50%;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  width: 12px !important;
}

.input-range__track {
  height: 0.25rem !important;
}

.sweet-container {
  font-size: 1.5rem;
}

.btn-sweet-cancel {
  background-color: #F5F6FD !important;
  color: #E58924 !important;
}

.btn-sweet-cancel:hover,
.btn-sweet-cancel:focus {
  color: #F5F6FD !important;
  background-color: #E58924 !important;
}

.btn-sweet-confirm,
.btn-sweet-confirm:focus {
  background-color: rgb(42, 113, 213) !important;
  color: #fff !important;
  border: 0.5px solid rgb(42, 113, 213) !important;
}

.btn-sweet-confirm:hover {
  color: rgb(42, 113, 213) !important;
  background-color: #fff !important;
  background-image: none !important;
  box-shadow: 0 0 0 3px transparent !important;
}

.content-price-product-item-solde {
  display: flex;
  gap: 10px;
  align-items: center;
}

.note-container-input {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #ced4da;
  padding: 0.5rem;
  border-radius: 0.375rem;
}

.switch2-rate,
.switch-rate input {
  width: min-content;
  /* padding: 50px; */
  /*flex*/
  display: flex;
  justify-content: center;
  align-items: center;
  /* Position */
  position: relative;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.switch-rate input {
  display: none;
}

.slider-rate {
  width: 65px;
  height: 23px;
  background-color: #c4c4c48f;
  display: block;
  border-radius: 50px;
  position: relative;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  cursor: pointer;
}

.slider-rate::before {
  content: "NON";
  width: 30px;
  height: 96%;
  background-color: #cf3918;
  /* background-color: #21a374; */
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.8s, background-color 2s;
}

input:checked+.slider-rate {
  background-color: #c4c4c4;
}

input:checked+.slider-rate::before {
  content: "OUI";
  background-color: #21a374;
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(calc(75px - 24px - 15px));
}

.switch2-rate .slider-rate {
  border-radius: 3px;
}

.switch2-rate .slider-rate::before {
  border-radius: 0;
}

.content-note-rate-avis {
  border-radius: 0;
}

.content-note-rate-avis .progress-bar {
  background-color: #EEC64A !important;
}
.text-describe-product-item-detail {
  color: #252525;
  font-size: 14;
  line-height: 27px;
  font-weight: normal;
  font-family:  var(--Family, "Montserrat");
}
.title-quantity-product {
  color: #11142D;
  font-family:  var(--Family, "Montserrat");
  font-size: 24px;
  line-height: 120%;
  font-weight: bold;
}
.container-quantite-produit {
  display: flex;
  gap: 30px;
  align-items: center;
}
.btn-theme-annim {
  position: relative;
    display: inline-block;
    padding: 12px;
    box-sizing: border-box;
    font-family: "Montserrat";
    font-size: 16px;
    line-height: 120%;
    font-weight: 700;
    text-align: center;
    text-shadow: none;
    transition: .5s !important;
    transform: perspective(1px) translateZ(0);
    overflow: hidden;
    color: #fff !important;
    background: linear-gradient(45deg, #E58924, #DB242A) !important;
    border-radius: 8px
}


.btn-theme-annim::before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition: .5s cubic-bezier(0.52, 1.64, 0.37, 0.66);
  background-color: #E58924;
}

.btn-theme-annim:hover::before {
  transform: scaleX(1)
}
.container-btn-add-to-cart-product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}
.btn-theme-fav-product {
  position: relative;
  display: inline-block;
  padding: 12px;
  box-sizing: border-box;
  font-family:  "Montserrat";
  font-size: 16px;
  line-height: 120%;
  font-weight: bold;
  text-align: center;
  text-shadow: none;
  transition: .5s !important;
  transform: perspective(1px) translateZ(0);
  overflow: hidden;
  color: #DB242A !important;
  background-color: #fff;
  overflow: visible;
  border: 1px solid #DB242A;
}


.btn-theme-fav-product:hover {
  background-color: #fff;
  border: 1px solid #DB242A;
}

.btn-theme-fav-product::after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  animation-duration: 1s
}

.btn-theme-fav-product:hover::after {
  border: 2px solid #DB242A;
  animation-name: border-ripple-out;
  animation-fill-mode: forwards
}

@keyframes border-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0
  }
}
.name-product-item {
  font-family:  var(--Family, "Montserrat");
  font-size: 18px;
  font-weight: bold;
  color: #000000;
  line-height: 120%;
}
.btn-wrap {
  display: flex;
  gap: 30px;
  align-items: center;
}
.btn-counter-item:disabled {
  border: 0;
}

.material-checkbox {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #777777;
  cursor: pointer;
}

.material-checkbox input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.checkmark {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 12px;
  border: 1px solid #777777;
  border-radius: 4px;
  transition: all 0.3s;
}

.material-checkbox input[type="checkbox"]:checked~.checkmark {
  background-color: #E58924;
  border-color: #E58924;
}

.material-checkbox input[type="checkbox"]:checked~.checkmark:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 6px;
  width: 4px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.material-checkbox input[type="checkbox"]:focus~.checkmark {
  box-shadow: 0 0 0 2px #E58924;
}

.material-checkbox:hover input[type="checkbox"]~.checkmark {
  border-color: #E58924;
}

.material-checkbox input[type="checkbox"]:disabled~.checkmark {
  opacity: 0.5;
  cursor: not-allowed;
}

.material-checkbox input[type="checkbox"]:disabled~.checkmark:hover {
  border-color: #E58924;
}
.category-filter-list-check {
  height: 250px;
  overflow-y: scroll;
}
.category-filter-list-check::-webkit-scrollbar {
  width: 6px;
}

.category-filter-list-check::-webkit-scrollbar-track {
  background: #fff;
}

.category-filter-list-check::-webkit-scrollbar-thumb {
  background-color: #E26726;
  border-radius: 20px;
  border: 3px solid transparent;
}

.filter-prix{
  margin-left: 13px;
  margin-right: 13px;
  position: relative;
}


input[type='radio'] {
  -webkit-appearance:none;
  width:20px;
  height:20px;
  border:1px solid ;
  border-radius:50%;
  outline:none;
  margin-right: 18px;
  /* box-shadow:0 0 5px 0px gray inset; */
}

input[type='radio']:before {
  content:'';
  display:block;
  width:60%;
  height:60%;
  margin: 20% auto;    
  border-radius:50%; 
}
input[type='radio']:checked {
  background: var(--themeColor, #E26726);
  border:1px solid var(--themeColor, #E26726);
}

input[type='radio']:hover {
  border:1px solid var(--themeColor, #E26726);

}

.custom_container {
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.custom_container .custom_sm__title {
  color: #252525;
  font-size: 20px;
  font-weight: 700;
}

.container-product-description .product_description {
  color: #252525;
}

.container-product-price .custom_sm__price {
  color: #000;
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 57.6px;
  letter-spacing: 0.144px;
}

.variation_content label {
  display: block;
}

.variation_content .btn_color_wrapper {
  border: 1.2px dashed #000;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  padding: 5px;
  border-radius: 50%;
}

.variation_content .btn_color_choose {
  width: 22px;
  height: 22px;
  border: none;
  border-radius: 50%;
}

.variation_content .btn_size_choose {
  border-radius: 6.9px;
  border: 1.637px solid #C2C2C2;
  background: #FFF;
  color: #000;
  font-family: Inter;
  font-size: 18.011px;
  font-weight: 600;
}

.variation_content .btn_size_choose.active {
  border-color: #B76102;
  color: #B76102;
}

.variation_content input[type='radio'] {
  display: none;
}