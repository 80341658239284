.row-validate .cart-card {
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);
    padding: 1.2rem 1rem 3rem;
}

.cart-card .title {
    color: #303030;
    font-family: var(--Family, "Montserrat");
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.98px;
    text-transform: uppercase;
}

.cart-card .sm-title {
    color: #303030;
    font-family: var(--Family, "Montserrat");
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.cart-card .sm-value {
    color: #303030;
    font-family: var(--Family, "Montserrat");
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.cart-card .sm-value.total {
    font-size: 19px;
}

.cart-card .separator {
    width: 100%;
    height: 1px;
    background: rgba(217, 217, 217, 0.50);
}

.validate-wrapper {
    padding-right: 2rem;
}

.tabs-wrapper ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.tabs-wrapper ul .tab-item {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #C8C8C8;
    color: #fff;
    display: grid;
    place-items: center;
    cursor: pointer;
}

.tabs-wrapper ul .tab-item-active, .tabs-wrapper ul .it0, .tabs-wrapper ul .tab-color {
    background: var(--couleur-2, #E58924);
}

.tabs-wrapper ul .tab-item .tab-indicator {
    color: #303030;
    font-family: var(--Family, "Montserrat");
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    position: absolute;
    top: 2.3rem;
}

.validate-wrapper .validate-progress {
    height: 4px;
    position: absolute;
    right: 2rem;
    left: 2rem;
    top: 0;
    bottom: 0;
    margin: auto 0;
    z-index: -1;
}

.validate-wrapper .validate-progress .custom-progress {
    background: var(--couleur-2, #E58924);
}

.infos_livraison_container input {
    padding-left: 3rem !important;
}

.infos_livraison_container .input_wrapper input {
    background: #fff;
}

.infos_livraison_container .icon_wrapper {
    position: absolute;
    top: 13px;
    bottom: 0;
    left: 10px;
    margin: auto 0;
    color: #666;
}

.cart_step_wrapper {
    background: #FAFAFA;
    padding: 0 1rem;
}