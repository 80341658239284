.btn-logout-compte {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #E20613;
  padding: 15px;
  margin-left: 20px;
  transition: all 0.2s;
}

.btn-logout-compte:hover {
  background: #E26726;
  color: #fff;
  font-weight: 700;
}
.list-sidebar-compte {
  list-style: none;
}

.btn-compte-sidebar {
  color: var(--Gray-3, #828282);
  font-family: var(--Family, "Montserrat");
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 2px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 1.3rem;
  padding: 1rem;
  border: none;
  position: relative;
}

.btn-compte-sidebar.active, .btn-compte-sidebar:hover {
  background: rgba(227, 111, 49, 0.08);
  color: #303030;
}

.btn-compte-sidebar.active:after {
  content: '';
  position: absolute;
  width: 3px;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  background: #E36F31;
}

.user-parcours-title {
  color: #333;
  font-family: var(--Family, "Montserrat");
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.parcours-submit-btn, .validate-go-back-btn  {
  display: inline-flex;
  padding: 13px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  font-family: var(--Family, "Montserrat");
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.72px;
  text-transform: uppercase;
  transition: .4s ease;
}

.parcours-submit-btn {
  color: #FFF;
  border: 1.4px solid transparent;
  background: var(--couleur-1, linear-gradient(308deg, #DB242A 4.2%, #E58924 64.82%));
}

.parcours-submit-btn:hover {
  border-color: #DB242A;
  color: #DB242A;
  background: transparent;
}

.validate-go-back-btn {
  border: 1.4px solid #DB242A;
  color: #DB242A;
  background: transparent;
}

.row-user-parcours ul {
  list-style: none;
}

.row-user-parcours .tab-item {
  border-radius: 2px;
  color: var(--Gray-3, #828282);
  font-family: var(--Family, "Montserrat");
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: .7rem 1rem;
  cursor: povar(--Family, "Montserrat");
  position: relative;
  margin-right: -1rem;
}

.user-parcours .tab-item-active {
  background: rgba(227, 111, 49, 0.08);
  color: #303030;
}

.user-parcours .tab-item-active:after {
  content: '';
  position: absolute;
  width: 3px;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  background: #E36F31;
}

.user-parcours .custom-side-bar {
  border-right: 3px solid rgba(217, 217, 217, 0.30);
}

.user-parcours .steps-content {
  margin-left: 1.3rem;
} 

.cart-total-card {
  padding: 1rem;
  border-radius: 3.901px;
  background: #fff;
  box-shadow: 0px 3.12057px 17.94326px 0px rgba(0, 0, 0, 0.07);
}

.cart-total-card .sm-title {
  color: #303030;
  font-family: var(--Family, "Montserrat");
  font-size: 10.922px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.765px;
  text-transform: uppercase;
}

.cart-total-card .separator {
  height: 1px;
  background: rgba(217, 217, 217, 0.50);
}

.commande-table {
  color: rgba(0, 0, 0, 0.70);
  font-family: var(--Family, "Montserrat");
  font-size: 12px;
  font-style: normal;
  line-height: normal;
}

.commande-table thead {
  font-weight: 600;
  border-bottom: 20px solid #fff;
}

.commande-table thead tr, .commande-table thead th {
  border-width: 0;
  text-align: center;
}

.commande-table tbody {
  font-weight: 500;
}

.commande-table tbody td {
  border-width: 0;
  text-align: center;
}

.commande-table tbody tr {
  border-width: 1px;
  vertical-align: middle;
  border-radius: 10px;
}

.custom-row-class {
  cursor: povar(--Family, "Montserrat");
}

.payment-status, .command-status {
  border-radius: 5px;
  padding: .5rem;
}

.command-status {
  border-radius: 5px;
  background: #DDD;
}

.command-status.inprogress {
  background: #333;
  color: #fff;
}

.payment-status.paid, .command-status.delivered {
  background: #22E080;
  color: #fff;
}

.payment-status.not-paid {
  background: #F42D2D;
  color: #fff;
}

.statut-content {
  flex-wrap: wrap;
}

.col-sidebar-content {
  margin-right: -1rem;
}

.action-btn-container {
  background: rgba(217, 217, 217, 0.10);
  padding: .5rem 1rem;
}

.action-btn-container .go-back-btn {
  border: 1.3px solid transparent;
  color: var(--themeColor, #E26726);
  font-family: var(--Family, "Montserrat");
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: .5rem;
  border-radius: 8px;
  background: transparent;
  transition: .4s ease;
}

.action-btn-container .go-back-btn:hover {
  border-color: var(--themeColor, #E26726);
}

.custom-btn-theme {
  display: inline-flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: var(--couleur-1, linear-gradient(308deg, #DB242A 4.2%, #E58924 64.82%));
  color: #FFF;
  font-family: var(--Family, "Montserrat");
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.72px;
  text-transform: uppercase;
  border: 1.4px solid transparent;
  transition: .4s ease;
}

.custom-btn-theme:hover {
  background: transparent;
  color: #DB242A;
  border-color: #DB242A;
}

.product-image-container img {
  width: 80px;
  height: 80px;
}

.product-image-container img.cart-image {
  width: 60px;
  height: 60px;
}

.commande-detail-table, .row-payment-mode {
  padding-top: 2rem;
}

.commande-detail-table, .row-payment-mode, .panier-table {
  color: #000;
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  line-height: normal;
}

.commande-detail-table thead {
  border-bottom: 20px solid transparent;
}

.commande-detail-table thead, .row-payment-mode .sm-title {
  font-size: 16px;
  font-weight: 600;
} 

.commande-detail-table thead tr, .commande-detail-table thead th, .commande-detail-table tbody tr, .commande-detail-table tbody td {
  border-width: 0;
  text-align: center;
  vertical-align: middle;
}

.commande-detail-table tbody, .row-payment-mode .sm-value {
  font-size: 14px;
  font-weight: 400;
}

.detail-page .separator {
  height: 1px;
  background: rgba(217, 217, 217, 0.50);
}

.sm-title.total {
  font-size: 18px;
}

.price-and-total-content {
  padding-top: 4rem;
}

.panier-table {
  background: #FAFAFA;
}

.panier-table thead tr th {
  padding: 1rem 0;
  color: #4F4F4F;
}

.panier-table thead tr, .panier-table thead th, .panier-table tbody td {
  border-width: 0;
  text-align: center;
  vertical-align: middle;
}

.panier-table .row-quantity {
  border-radius: 2px;
  border: 1px solid #D9D9D9;
}

.panier-table .row-quantity button {
  border: none;
  background: transparent;
}

.panier-table .row-quantity .btn-wrapper {
  border-left: 1px solid #D9D9D9;
  border-right: 1px solid #D9D9D9;;
}
.product-category-name {
  font-family:  var(--Family, "Montserrat");
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #999999;
}
.product-name-panier {
  font-family:  var(--Family, "Montserrat");
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  color: #001530;
}
.info-produit-item-panier-page {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.command-and-payment-status {
  width: 260px;
  font-size: 8px;
}

.breadcrumb-row .home_breadcrumb_link {
  color: #2A2E34;
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
}

.home_breadcrumb_link:hover {
  color: #E26726;
}

.breadcrumb-row .other_level_link {
  color: rgba(142, 142, 142, 0.80);
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}