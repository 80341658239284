.who-we-are-page .who-we-are, .who-we-are-page .plateform-founders, .who-we-are-page .our-team {
    padding: 5rem 0;
    position: relative;
}

.who-we-are-page .plateform-founders {
    background: rgba(229, 137, 36, 0.09);
}

.who-we-are-page .custom-esb-title {
    color: rgba(0, 0, 0, 0.50);
    font-family: var(--Family, "Montserrat");;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    position: relative;
    padding-left: 110px;
}

.who-we-are-page .custom-esb-title:before {
    content: "";
    width: 100px;
    height: 2px;
    background: rgba(0, 0, 0, 0.20);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto 0;
}

.who-we-are-page .custom-title {
    color: rgba(0, 0, 0, 0.80);
    font-family: var(--Family, "Montserrat");
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 68.39px;
}

.who-we-are-page .custom-content {
    color: #000;
    font-family: var(--Family, "Montserrat");
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
}

.who-we-are-page .founder-card {
    padding: 1.5rem;
    border-radius: 7px;
    background: #FFF;
    display: grid;
    place-items: center;
    row-gap: 15px;
}

.who-we-are-page .founder-card, .who-we-are-page .team-card {
    display: grid;
    place-items: center;
}

.who-we-are-page .founder-card span {
    display: block;
}

.who-we-are-page .founder-card .photo-wrapper, .who-we-are-page .founder-card .photo-wrapper img {
    width: 191px;
    height: 191px;
    border-radius: 50%;
}

.who-we-are-page .founder-card .name, .who-we-are-page .team-card .name {
    color: #2C3136;
    text-align: center;
    font-family: var(--Family, "Montserrat");
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 55.652px;
}

.who-we-are-page .founder-card .description {
    color: #000;
    font-family: var(--Family, "Montserrat");
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    text-align: justify;
}

.who-we-are-page .team-card {
    padding: .5rem 1rem 1.3rem;
    border-radius: 7.296px;
    background: linear-gradient(0deg, rgba(229, 137, 36, 0.06) 2.53%, rgba(255, 255, 255, 0.00) 97.47%);
    box-shadow: 0px 2.9185px 24.80726px 0px rgba(0, 0, 0, 0.08);
    height: 100%;
    transition: .4s ease;
    position: relative;
    top: 0;
}

.who-we-are-page .card-wrapper:hover .team-card {
    top: -12px;
}

.who-we-are-page .team-card .role {
    color: #2C3136;
    text-align: center;
    font-family: var(--Family, "Montserrat");
    font-size: 12.271px;
    font-style: normal;
    font-weight: 300;
    line-height: 56.911px;
    letter-spacing: 3.681px;
    text-transform: uppercase;
}

.who-we-are-page .team-card .social-networks a {
    color: #C2C2C2;
}