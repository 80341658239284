
.page-content {
  margin-top: 135px;
}
.section-banner-homepage {
  background: #E26726;
  padding: 50px 100px;
}
.container-home-page {
  background: #ffffff;
  padding: 0px 20px;
}
.container-easy-market {
  padding: 3rem 45px 0;
}
.title-home-page {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
    font-weight: 800;
    font-size: 53px;
    line-height: 90px;
    color: #fff;
}
.text-home-page-banner {
  font-family: var(--Family, "Montserrat");
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  color: #fff;
}
.btn-title-home-page {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  box-shadow: 0px 4px 30px 0px #0000000D;
  border: 1px solid #FFFFFF;
  background-color: #ffffff33;
  text-transform: uppercase;
  padding: 15px;
}
.btn-title-home-page:hover {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #E26726;
  box-shadow: 0px 4px 30px 0px #0000000D;
  border: 1px solid #FFFFFF;
  background-color: #FFFFFF;
  text-transform: uppercase;
  padding: 15px;
}
.content-col-right-banner-accueil {
  position: relative;
}
.container-img-col-right-banner-accueil {
  position: relative;
  z-index: 2;
}
.img-col-right-banner-accueil {
  width: 117%;
}
.container-img-deco-banner {
  position: absolute;
      right: 73px;
        bottom: -153px;
}
.title-category-product-item {
  font-family: var(--Family, "Montserrat");
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: center;
    color: #333333;
}
.content-icon-category-product-accueil {
  background-color: #fff;
  border: 1px solid #D9D9D9;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.category-product-accueil-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container-list-category-product {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.title-home-page-theme {
  font-family: var(--Family, "Montserrat");
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0em;
    color: #333333;
}
.product-item-container {
  position: relative;
  background: #fff;
  width: 100%;
  border-radius: 9px;
  transition: all .2s ease-in-out;
}
.product-item-container:hover {
  /* transform: scale(1.1); */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.212);
}
.product-item-container:hover .img-product-item {
  transform: scale(1.01);
}
.statut-product-item {
  position: absolute;
  right: 0;
  top: 20px;
  border-radius: 20px 1px 1px 20px;
  background: #E58924;
  padding: 6px 20px;
  font-family:  var(--Family, "Montserrat");
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  color: #fff;
}
.icon-fav-product {
  position: absolute;
  top: 257px;
  right: 2%;
  color: #E58924;
  font-size: 20px;
  width: 40px;
  height: 40px;
  box-shadow: 0px 4px 43px 0px #00000014;
  border-radius: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
.icon-fav-product:hover {
  border: 1px solid #E58924;
  color: #E58924;
}
.price-product-item {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #333333;
}
.content-img-product-item {
  border: 1px solid #EDEDED;
    border-bottom: none !important;
    border-radius: 9px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.container-body-product-item {
  padding: 12px;
  border: 1px solid #EDEDED;
  border-top: none !important;
  border-radius: 9px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.name-prduct {
  font-family:  var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 38px;
  color: #001530;
}
.text-describe-product-item {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 300;
  font-size: 13.344px;
  line-height: 23px;
  color: #000000;
  /* height: 80px;
  overflow-y: scroll; */
}
.img-product-item {
  width: 100%;
  height: 280px;
  object-fit: cover;
  border-radius: 9px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.alice-carousel__stage-item {
  margin: 15px 25px !important;
}
.alice-carousel {
  position: relative;
}
.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  display: flex !important;
  box-sizing: border-box;
  width: 45px !important;
  height: 45px !important;
  padding: 0 !important;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0px 6px 30px 0px #0000003D;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  top: 40%;
  position: absolute;
}
.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  padding: 0 !important;
  color: #000 !important;
}
.alice-carousel__prev-btn {
  
  left: -62px;
}
.alice-carousel__next-btn {
  right: -62px;
}
.alice-carousel__dots-item:not(.__custom) {
  background-color: #C4C4C4 !important;
}
.alice-carousel__dots-item:not(.__custom):hover,
.alice-carousel__dots-item:not(.__custom).__active {
  background-color: #E58924 !important;
}
.btn-see-more-product {
  font-family:  var(--Family, "Montserrat");
  font-size: 21px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  color: #000;
}
.btn-see-more-product:hover {
  color: #E58924;
  font-weight: 700;
}
.container-testimonial-item {
  box-shadow: 0px 4px 100px 0px #AFADB51A;
  background-color: #fff;
  padding: 15px;
}
.text-item-testimonial {
    font-family:  var(--Family, "Montserrat");
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    color: #AFADB5;
}
.content-footer-testimonial {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content-item-footer-testimonial {
  display: flex;
  gap: 10px;
}
.img-pp-testimonial {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.name-user-testimonial {
    font-family:  var(--Family, "Montserrat");
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    color: #151411;
}
.icon-rate-user-testimonial {
  color: #FFB23F;
}
.content-news-letter {
  background: #FEFAF6;
  padding: 30px;
}
.title-news-letter {
  font-family: var(--Family, "Montserrat");
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  color: #333333;
}
.sub-title-news-letter {
  font-family: var(--Family, "Montserrat");
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: #333333;
}
.form-control-news-letter {
  border: 1px solid #E8EAEC;
  border-radius: 26px;
  font-family: var(--Family, "Montserrat");
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: #9A9A9A;
  background-color: #fff;
  padding: 15px 20px;
}
.btn-send-news-letter {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: #E58924;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: 20px;
  position: absolute;
  right: -15px;
  top: 0;
}
.content-col-left-select-product-bg {
  background-image: url(../../assets//appImages//bg-select.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  width: 100%;
  position: relative;
  padding: 30px 20px;
}
.content-btn-col-left-select-product-bg {
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
}
.container-cta-chat-produit {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus, 
.image-gallery-thumbnail:hover{
  border: 4px solid #B76102;
  border-radius: 4px;
}
.alice-carousel__wrapper {
  padding-bottom: 60px;
  padding-top: 60px;
}
.statut-product-item-rupture {
  background: #999999;
  position: absolute;
  right: 0;
  top: 20px;
  border-radius: 20px 1px 1px 20px;
  padding: 6px 20px;
  font-family:  var(--Family, "Montserrat");
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  color: #fff;
}
.btn-theme-outline.disabled {
  color: #999999 !important;
}
.content-btn-achat-produit .btn-theme.disabled {
  background: #999999 !important;
  border: 0;
  color: #fff !important;
}

.catgoryImg{
  width: 117px;
  height: 118;
  border-radius: 50%;
}