.custom-page-title {
    color: #383838;
    text-align: center;
    font-family: var(--Family, "Montserrat");
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    padding: 5rem 0 2rem 0;
}

.custom-politique-page-content {
    color: #000;
    font-family: var(--Family, "Montserrat");
    font-size: 13px;
    line-height: 33.584px;
    font-style: normal;
    font-weight: 300;
}

.custom-sm-title {
    font-weight: 700;
    font-size: 15px;
}

.custom-sm-value {
    font-weight: 300;
    text-align: justify;
}