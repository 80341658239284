button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}

#navbarNav {
  margin-left: 180px;
}

.headering {
  background: #fff;
  box-shadow: 0px 4px 20px 0px #0000000D;
  width: 100%;
}

.firstheader-costum {
  padding: 20px 100px;
  display: flex;
  align-items: center;
  width: 100%;position: absolute;
    z-index: 99;
  background: #fff;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 14%);
}

.dropdown-toggle::after {
  border-top: 0.2em solid !important;
  border-right: 0.2em solid transparent !important;
  border-left: 0.2em solid transparent !important;
  vertical-align: 2px !important;
}

.header-items-container {
  display: flex;
  align-items: center;
}

.header-container-fluid {
  padding-bottom: 15px;
}

.navbar-nav {
  display: flex;
  align-items: center;
}

.navbar-nav .navbar-btn-container .navbar-nav {
  display: flex;
  align-items: center;
}

.nav-item-btn-end {
  display: flex;
  align-items: center;
}

.connected-user-content .dropdown-toggle::after {
  display: none;
}

.header-navbar-contents-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu-link {
  font-family: var(--Family, "Montserrat");
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  letter-spacing: 0.291667px;
  color: #666666 !important;
  border: 0 !important;
  border-radius: 0 !important;
  
}
.nav-item {
border-right: 1px solid #D9D9D9;
    padding: 0 25px;
}
.nav-item:last-child {
  border-right: inherit;
  padding-right: 0;
}
.menu-link:hover,
.menu-link.active,
.menu-link:focus,
.menu-link:visited {
  color: #B76102;
}
.menu-link svg {
  color: #B76102;
}
.second-header-custom {
  padding: 15px 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #0000001a
}

.text-second-header-custom {
  font-family: var(--Family, "Montserrat");
    font-size: 15px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
  color: #333333;
  margin-bottom: 0;
  text-decoration: none;
}

.text-second-header-custom.btn:hover,
.text-second-header-custom.btn.active {
  color: #333333 !important;
  font-weight: 700;
  border: 0;
}

.content-left-second-header,
.content-right-second-header {
  display: flex;
  gap: 10px;
}

.b-b-1-white {
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}

.bloc-flex-info-user-header {
  position: relative;
}
.categorie-btn-container {
      position: absolute;
        right: 14px;
        top: 14px;
}

.search-field {
  background: #d9d9d947;
  border-radius: 10px !important;
  width: 450px;
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
  padding: 17px 40px !important;
  border: 0;
}
.input-group-btn {
  position: absolute;
  left: 14px;
  top: 12px;
  color: #B76102;
}
.categorie-btn-container .dropdown-toggle::after {
  display: none !important;
}

.counter-shop-notif {
  position: absolute;
  top: 1px;
  left: 5px;
  background: #fd0000;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 6px;
  color: #ffffff;
}

.input-group-search {
  display: flex;
  width: 100%;
}

.dropdown-menu-header-category {
  background: #ffffff;
  border-left: 1px solid #B76102;
  border-radius: 10px;
}
.menu-link-category {
  color: #B76102 !important;
}
.menu-link-category:focus,
.menu-link-category.active {
  color: #B76102 !important;
  font-weight: 700;
  border: 0 !important;
}

.dropdown-item-title {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
}

.dropdown-item-categorie,
.dropdown-item-categorie.active {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 206.4%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 10px;
  background-color: transparent;
}

.dropdown-item-categorie:hover {
  background: #e8f1fb;
  border-left: 5px solid #B76102;
  color: #B76102;
  font-weight: 700;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  background: #e8f1fb;
  border-radius: 0px 10px 10px 0px;
  min-width: 200px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 12px 12px;
  z-index: 1;
}

#dropdown:hover .dropdown-content {
  display: block;
}

.sub-category-list {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 206.4%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #000000;
}

.content-info-category-header {
  background: #ffffff;
  border-radius: 9px;
  padding: 20px;
}

.ReactModal__Overlay--after-open {
  background-color: rgb(53 52 52 / 75%) !important;
  z-index: 9999;
  border: 0 !important;
}

.flex-r-header-modal {
  justify-content: flex-end !important;
}

.title-login-modal {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 44px;
  color: rgba(0, 0, 0, 0.85);
}

.text-resgister-modal-login {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #444150;
}

.btn-register-login-modal,
.btn-register-login-modal.active {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #e20613;
  border-color: transparent;
  background-color: transparent;
}

.btn-register-login-modal:hover {
  color: #e20613;
  text-decoration: underline;
  font-weight: 700;
}

.form-label-login-modal {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #444150;
}

.form-control-login-modal {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #444150a5;
  padding: 15px;
}

.btn-theme,
.btn-theme.active {
  font-family:  var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  background: #000;
  border-radius: 10px;
  padding: 9px;
  transition: 0.2s;
  border-color: transparent;
}

.btn-theme:hover {
  color: #000;
  border: 1px solid #000;
  background: #fff;
}

.cartItem-container {
  max-height: 500px;
  overflow: auto;
}

.icon-heard-product {
  position: absolute;
  /* top: 20px;
    right: 12px;
    padding: 0; */
  border: 0;
  background: transparent;
  position: absolute;
  top: 0;
  right: 0%;
  color: #666666;
  font-size: 20px;
}

.search-result-container {
  padding: 0px 15px;
  background: #fff;
  position: absolute;
  width: 100%;
  border: 1px solid #ececec;
  z-index: 999;
  border-radius: 10px;
}

.search-field:focus+.search-result-container {
  padding: 1rem 2rem;
  background: #fff;
  position: absolute;
  width: 100%;
  border: 1px solid #7b1cc32a;
  display: block;
}

.search-result-item {
  padding: 5px 0;
  display: block;
  color: #000000ef;
  font-family: "Raleway", sans-serif;
  transition: 0.2s ease-in-out;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.2;
}