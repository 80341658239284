.title-banner-policy {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 39px;
  color: #2a71d5;
}

.text-policy,
.text-policy p,
.text-policy ol,
.text-policy ul,
.text-policy pre,
.text-policy blockquote,
.text-policy h1,
.text-policy h2,
.text-policy h3,
.text-policy h4,
.text-policy h5,
.text-policy h6 {
  font-family: var(--Family, "Montserrat");
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 209.9%;
  color: #000000;
  text-align: justify;
}

.container-text-policy {
  margin-top: 50px;
  margin-bottom: 50px;
}